import React, { useEffect, useState } from 'react';
import './App.css';
import loaderImage from './assets/loader.gif'; // Ensure this path is correct

function App() {
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const queryParams = new URLSearchParams(window.location.search);
  const data = queryParams.get('link-seq');

  useEffect(() => {
    if (!data) {
      setError('No data query parameter provided.');
      // setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(`https://login.icepe.in/api/MerchantPanel/merchant/link/get/${data}`);
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const result = await response.json();
        setApiResponse(result);
      } catch (error) {
        setError(error.message);
      } finally {
        
      }
    };

    fetchData();
  }, [data]);
  useEffect(() => {
    // Redirect based on the updated apiResponse state
      if (apiResponse && apiResponse.statusCode === 200) {
    
        // Assuming your API response includes the URL and form data to redirect
        const redirectUrl = "https://pg.icepe.in/paymentrequest"; // Your target URL for redirection
        const formData = apiResponse.data; // Assuming this is the correct path to your form data in the response
        const queryString = new URLSearchParams(formData).toString();

        window.location.href = `${redirectUrl}?${queryString}`;
      }
  }, [apiResponse]); // This useEffect is triggered when apiResponse is updated

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        width: '100vw',  // Full viewport width
      }}>
        <div style={{
          background: `url(${loaderImage}) no-repeat center center`,
          backgroundSize: 'contain', // Image will resize to fit
          width: '150px', // Fixed size for loader
          height: '150px',
        }} />
      </div>
    );
    
  }

  return (
    <div className="App">
    {loading ? (
           <img src={loaderImage} alt="Loading..." />
         ) : (
           <p></p>
         )}
     </div>

  );
}

export default App;
